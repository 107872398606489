import React from "react"
import PrimaryLayout from "../layouts/PrimaryLayout"

const About = () => (
  <>
    <PrimaryLayout  column="col-xs-6">
      <h1>About us</h1>
      <p>
        Suppose end get boy warrant general natural. Delightful met sufficient
        projection ask. Decisively everything principles if preference do
        impression of. Preserved oh so difficult repulsive on in household. In
        what do miss time be. Valley as be appear cannot so by.
      </p>
    </PrimaryLayout>
  </>
)

export default About
